<script setup lang="ts">
import { ref } from "vue";
import { ClearAllIcon, Menu2Icon, ChevronDownIcon, ChevronUpIcon, BrandGooglePlayIcon, LoginIcon } from 'vue-tabler-icons';


const open = ref(false)
const dropdownNavbar = ref(false)

function dropdownToggler() {
    dropdownNavbar.value = !dropdownNavbar.value
}
</script>
 
  
<template>
    <nav id="navbar" class="relative z-10 w-full text-neutral-800">
        <div class="flex flex-col max-w-screen-xl px-8 mx-auto lg:items-center lg:justify-between lg:flex-row py-4">
            <div class="flex flex-col lg:flex-row items-center space-x-4 xl:space-x-8">
                <div class="w-full flex flex-row items-center justify-between py-6">
                    <div>
                        <img src="/images/original.png" class="w-14 xl:w-16" alt="New Life Aged and Disability Care" />
                    </div>
                    <button class="rounded-lg lg:hidden focus:outline-none focus:shadow-outline" @click="open = !open">
                        <Menu2Icon v-if="!open" :size="24" />
                        <ClearAllIcon v-else :size="24" />
                    </button>
                </div>
                <ul :class="[open ? 'flex' : 'hidden lg:flex']"
                    class="w-full h-auto flex flex-col flex-grow lg:items-center pb-4 lg:pb-0 lg:justify-end lg:flex-row origin-top duration-300 xl:space-x-2 space-y-3 lg:space-y-0">
                    <NavLink name="Home" url="#" />
                    <NavLink name="About" url="#" />
                    <NavLink name="Contact" url="#" />
                     <li class="relative group">
                        <button
                            class="md:px-4 py-2 text-sm bg-transparent rounded-lg text-[#666666] hover:text-gray-900 focus:outline-none focus:shadow-outline flex items-center"
                            @click="dropdownToggler" @blur="dropdownToggler">
                            <span>  Services</span>
                            <ChevronUpIcon v-if="dropdownNavbar" :size="16" />
                            <ChevronDownIcon v-else :size="16" />
                        </button>
                        <transition name="transform-fade-down">
                            <ul v-if="dropdownNavbar"
                                class="flex lg:absolute flex-col max-w-42 py-1 lg:bg-white rounded-md lg:shadow-md pl-2 lg:pl-0">
                                <li>
                                    <a href="#" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">Exchange</a>
                                </li>
                                <li>
                                    <a href="#" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">Wallet</a>
                                </li>
                                <li>
                                    <a href="#" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">Explorer</a>
                                </li>
                                <li>
                                    <a href="#" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">Charts</a>
                                </li>
                            </ul>
                        </transition>
                    </li>
                </ul>
            </div>
            <div :class="[open ? 'flex' : 'hidden lg:flex']" class="space-x-3">
                <button
                    class="flex align-center gap-2  hover:text-white text-green-500 hover:bg-green-500   border-2 border-green-300 duration-200 ease-out  rounded-lg px-8 py-3 bg-white">
                    <component :is="BrandGooglePlayIcon" size="20" stroke-width="1.5" class="iconClass">
                    </component>

                    Play Store
                </button>
                <nuxt-link to="/auth/login"
                    class="flex align-center gap-2  hover:text-white hover:bg-teal-400    border-green-300 duration-200 ease-out text-white  rounded-lg px-8 py-3 bg-green-500 ">
                    <component :is="LoginIcon" size="20" stroke-width="1.5" class="iconClass">
                    </component>

                    Login
                </nuxt-link>
               
            </div>
        </div>
    </nav>
</template>

